import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-redirect-brooklyn',
  template: ''
})
export class RegisterRedirectBrooklynComponent implements OnInit{
  constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = 'https://app.januus.com/#/brooklynchamber';
    }
  }
}
